import postRequest from '../../../utils/requests/postRequest';
import deleteRequest from '../../../utils/requests/deleteRequest';
import config from '../../../config/config';
import { compress, decompress } from 'shrink-string';
import getCsrf from '../../../utils/cookies/getCsrf';
import encryptDecrypt from '../../../utils/cryptography/encryptDecrypt';
import moment from 'moment';

export const getAllDatabases = (data={}, componentId='') => async dispatch => {
    if(!componentId){
        dispatch({type : 'GET_DATABASES_LOADING'});
    }else{
        dispatch({
            type : 'SET_MAPPED_DATABASES',
            componentId,
            payload : {
                getDatabasesLoading : true,
                getDatabasesError : false
            }
        });
    }
    let request = await postRequest(`${config.devServerProxy}/api/database/getall`, 'POST', data);
    if(!request.hasError && !request.error){
        if(
            componentId && 
            typeof componentId === 'string'
        ){
            dispatch({
                type : 'SET_MAPPED_DATABASES',
                componentId,
                payload : {
                    databases : request,
                    getDatabasesLoading : false,
                    getDatabasesError : false
                }
            });
        }else{
            dispatch({
                type : 'GET_DATABASES_DATA',
                payload : request
            });
        }
    }else{
        if(
            componentId && typeof componentId === 'string'
        ){
            dispatch({
                type : 'SET_MAPPED_DATABASES',
                componentId,
                payload : {
                    getDatabasesLoading : false,
                    getDatabasesError : true
                }
            });
        }else{
            dispatch({type : 'GET_DATABASES_ERROR'});
        }
    }
}

export const createDatabase = (data={}) => async dispatch => {
    dispatch({type : 'CREATE_DATABASE_LOADING'})
    let request = await postRequest(`${config.devServerProxy}/api/database/create`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'CREATE_DATABASE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({type : 'CREATE_DATABASE_ERROR'});
    }
}

export const editDatabase = (data={}) => async dispatch => {
    dispatch({type : 'EDIT_DATABASE_LOADING'});
    let request = await postRequest(`${config.devServerProxy}/api/database/edit`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'EDIT_DATABASE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({type : 'EDIT_DATABASE_ERROR'});
    }
}

export const changeDatabasePassword = (data={}) => async dispatch => {
    dispatch({type : 'EDIT_DATABASE_LOADING'});
    let request = await postRequest(`${config.devServerProxy}/api/database/changepassword`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'EDIT_DATABASE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({type : 'EDIT_DATABASE_ERROR'});
    }
}

export const editDatabaseReducer = (data={}) => {
    return {
        type : 'EDIT_DATABASE_REDUCER',
        payload : data
    }
}

export const getDatabase = (data={}) => async dispatch => {
    dispatch({type : 'GET_DATABASE_LOADING'});
    let request = await postRequest(`${config.devServerProxy}/api/database/getdatabase`, 'POST', data);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'GET_DATABASE_DATA',
            payload : request
        });
    }else{
        dispatch({type : 'GET_DATABASE_ERROR'});
    }
}

export const resetDatabaseReducer = () => {
    return {
        type : 'RESET_DATABASE_REDUCER'
    }
}

export const deleteDatabase = (_id) => async dispatch => {
    dispatch({type : 'DELETE_DATABASE_LOADING'});
    let request = await deleteRequest(`${config.devServerProxy}/api/database/delete/${_id}`);
    if(!request.hasError && !request.error){
        dispatch({
            type : 'DELETE_DATABASE_SUCCESS',
            payload : request
        });
    }else{
        dispatch({type : 'DELETE_DATABASE_ERROR'});
    }
}

export const testDatabaseConnection = (data={}, environment) => async dispatch => {
    dispatch({
        type : 'TEST_DATABASE_CONNECTION',
        environment,
        payload : {
            loading : true,
            hasError : false,
            errorMessage : ''
        }
    });
    let response = await fetch(
        `${config.devServerProxy}/api/remoteexecution/testconnection`,
        {
            method : 'POST',
            headers : {
                'Accept':"application/json",
                'Content-Type':"application/json",
                'Cache': 'no-cache',
                'x-csrfToken' : getCsrf()
            },
            credentials : 'include',
            body : JSON.stringify(data)
        }
    )
    .then(res => {
        let jsonResponse = res.json();
        return jsonResponse;
    })
    .catch(async (error) => {
        let err = encryptDecrypt(
            JSON.stringify({
                databaseResponse : {
                    error : {
                        message : error.message
                    }
                },
                expirationTimestamp : moment().add(1000, 'seconds').unix() * 1000
            }),
            true
        );
        let compressedErr = await compress(err);
        return {
            encryptedData : compressedErr
        }
    });
    let decompressedResponse = await decompress(response['encryptedData']);
    let decryptedResponse = encryptDecrypt(
        decompressedResponse, 
        false
    ); 
    let parsed = JSON.parse(decryptedResponse);
    let request = parsed['databaseResponse'];
    if(!request.error){
        dispatch({
            type : 'TEST_DATABASE_CONNECTION',
            environment,
            payload : {
                loading : false,
                hasError : false,
                errorMessage : '',
                success : true
            }
        });
    }else{
        dispatch({
            type : 'TEST_DATABASE_CONNECTION',
            environment,
            payload : {
                loading : false,
                hasError : true,
                errorMessage : (request.error && request.error.message) || request.error,
                success : false
            }
        });
    }
}