import postRequest from '../../utils/requests/postRequest';
import config from '../../config/config';

export const setModuleTransferReducer = (data={}) => dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : data
    });
}

export const resetModuleTransferReducer = () => dispatch => {
    dispatch({
        type : 'RESET_MODULE_TRANSFER_REDUCER'
    });
}

export const getModuleTransferPackages = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            getModuleTransferPackagesLoading : true,
            getModuleTransferPackagesError : false,
            getModuleTransferPackagesErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/getpackages`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferPackagesLoading : false,
                getModuleTransferPackagesError : false,
                getModuleTransferPackagesErrorMessage : '',
                moduleTransferPackages : request.results || [],
                moduleTransferPackagesTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferPackagesLoading : false,
                getModuleTransferPackagesError : true,
                getModuleTransferPackagesErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const createModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            createModuleTransferPackageLoading : true,
            createModuleTransferPackageError : false,
            createModuleTransferPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/createpackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                createModuleTransferPackageLoading : false,
                createModuleTransferPackageError : false,
                createModuleTransferPackageErrorMessage : '',
                moduleTransferPackageInFocus : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                createModuleTransferPackageLoading : false,
                createModuleTransferPackageError : true,
                createModuleTransferPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const editModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            editModuleTransferPackageLoading : true,
            editModuleTransferPackageError : false,
            editModuleTransferPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/editpackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                editModuleTransferPackageLoading : false,
                editModuleTransferPackageError : false,
                editModuleTransferPackageErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                editModuleTransferPackageLoading : false,
                editModuleTransferPackageError : true,
                editModuleTransferPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const deleteModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            deleteTransferModulePackageLoading : true,
            deleteTransferModulePackageError : false,
            deleteTransferModulePackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/deletepackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                deleteTransferModulePackageLoading : false,
                deleteTransferModulePackageError : false,
                deleteTransferModulePackageErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                deleteTransferModulePackageLoading : false,
                deleteTransferModulePackageError : true,
                deleteTransferModulePackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const runModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            runModuleTransferPackageLoading : true,
            runModuleTransferPackageError : false,
            runModuleTransferPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransferparser/run`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                runModuleTransferPackageLoading : false,
                runModuleTransferPackageError : false,
                runModuleTransferPackageErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                runModuleTransferPackageLoading : false,
                runModuleTransferPackageError : true,
                runModuleTransferPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            getModuleTransferPackageLoading : true,
            getModuleTransferPackageError : false,
            getModuleTransferPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/getpackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferPackageLoading : false,
                getModuleTransferPackageError : false,
                getModuleTransferPackageErrorMessage : '',
                moduleTransferPackageInFocus : request || {}
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferPackageLoading : false,
                getModuleTransferPackageError : true,
                getModuleTransferPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const getModuleTransferLogs = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            getModuleTransferlogsLoading : true,
            getModuleTransferLogsError : false,
            getModuleTransferLogsErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/gettransferlogs`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferlogsLoading : false,
                getModuleTransferLogsError : false,
                getModuleTransferLogsErrorMessage : '',
                moduleTransferLogs : request.results || [],
                moduleTransferLogsTotalCount : request.total_count || 0
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                getModuleTransferlogsLoading : false,
                getModuleTransferLogsError : true,
                getModuleTransferLogsErrorMessage : request.errorMessage || ''
            }
        })
    }
}

export const acceptModuleTransferPackage = (data={}) => async dispatch => {
    dispatch({
        type : 'SET_MODULE_TRANSFER_REDUCER',
        payload : {
            acceptModuleTransferPackageLoading : true,
            acceptModuleTransferPackageError : false,
            acceptModuleTransferPackageErrorMessage : ''
        }
    });
    let request = await postRequest(`${config.devServerProxy}/api/moduletransfer/acceptpackage`, 'POST', data);
    if(
        !request.hasError && !request.error
    ){
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                acceptModuleTransferPackageLoading : false,
                acceptModuleTransferPackageError : false,
                acceptModuleTransferPackageErrorMessage : ''
            }
        });
    }else{
        dispatch({
            type : 'SET_MODULE_TRANSFER_REDUCER',
            payload : {
                acceptModuleTransferPackageLoading : false,
                acceptModuleTransferPackageError : true,
                acceptModuleTransferPackageErrorMessage : request.errorMessage || ''
            }
        })
    }
}