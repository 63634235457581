const initialState = {
    getModuleTransferPackagesLoading : false,
    getModuleTransferPackagesError : false,
    getModuleTransferPackagesErrorMessage : '',
    moduleTransferPackages : [],
    moduleTransferPackagesTotalCount : 0,
    getModuleTransferPackageLoading : false,
    getModuleTransferPackageError : false,
    getModuleTransferPackageErrorMessage : '',
    moduleTransferPackageInFocus : {},
    deleteTransferModulePackageLoading : false,
    deleteTransferModulePackageError : false,
    deleteTransferModulePackageErrorMessage : '',
    runModuleTransferPackageLoading : false,
    runModuleTransferPackageError : false,
    runModuleTransferPackageErrorMessage : '',
    editModuleTransferPackageLoading : false,
    editModuleTransferPackageError : false,
    editModuleTransferPackageErrorMessage : '',
    createModuleTransferPackageLoading : false,
    createModuleTransferPackageError : false,
    createModuleTransferPackageErrorMessage : '',
    getModuleTransferlogsLoading : false,
    getModuleTransferLogsError : false,
    getModuleTransferLogsErrorMessage : '',
    moduleTransferLogs : [],
    moduleTransferLogsTotalCount : 0,
    acceptModuleTransferPackageLoading : false,
    acceptModuleTransferPackageError : false,
    acceptModuleTransferPackageErrorMessage : '',
    viewInFocus : '',
    packageViewInFocus : ''
}

export default function(state = initialState, action){
    switch(action.type){
        case 'SET_MODULE_TRANSFER_REDUCER':
            return {
                ...state,
                ...action.payload
            }
        case 'RESET_MODULE_TRANSFER_REDUCER':
            return initialState;
        default:
            return state;
    }
}